import { autoinject, bindable, observable } from 'aurelia-framework';
import { FileAttachmentModels } from 'models/FileAttachmentModels';
import { ErrorService } from 'services';
import { SystemFileStorageService } from 'services/system-file-storage-service';
import './system-file.scss';

@autoinject
export class SystemFile {
  @bindable()
  @observable()
  fileId?: number;
  fileIdChanged(fileId: number) {
    if (!fileId) return;
    void this.getMetaData(fileId);
  }

  @bindable()
  fileOnly = false;

  @bindable()
  onFileDelete: () => void;

  @bindable()
  onFileSelected: ({ file }: { file: File }) => void;

  @bindable()
  accept = '.pdf';

  protected fileMetaData: FileAttachmentModels.FileAttachment = undefined;

  constructor(
    private systemFileStorageService: SystemFileStorageService,
    private errorService: ErrorService
  ) {}

  protected updateFilesSelect(event: Event) {
    const target = event.target as HTMLInputElement;
    const file = target.files[0];
    this.onFileSelected?.({ file });
  }

  protected async downloadFile() {
    try {
      await this.systemFileStorageService.download(this.fileId, true);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected async getMetaData(fileId: number) {
    try {
      this.fileMetaData = await this.systemFileStorageService.getMetaData(fileId);
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
